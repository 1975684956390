import { sorting } from '@/mixins'
import { storePagination } from '@/mixins/store'
import i18n from '@/libs/i18n'
import axios from '@axios'

export const getInitialSecurityApprovalFlowForm = () => ({
  name: '',
  active: true,
  email: '',
  document: '',
  approvalFlowName: '',
  mainStore: '',
  allowAllStores: false,
  stores: [],
  storesSelected: [],
  accessProfilesSelected: [],
  password: '',
  confirmPassword: '',
})

const getInitialState = () => ({
  ...storePagination.state(),
  sorting: {
    sortBy: 'createdDate',
    sortDesc: true,
  },
  approvalFlows: [],
  approvalFlow: null,
  events: [],
  filters: {
    status: null,
    entityId: null,
    type: null,
    storeId: null,
    pendingForMe: false,
  },
  files: [],
  uploadType: 'ApprovalFlowTransactionStep',
})

export default {
  namespaced: true,
  state: getInitialState(),

  getters: {
    approvalObject(state) {
      return `${i18n.t(`ApprovalFlow.Type.${state.approvalFlow?.type}`)}: #${
        state.approvalFlow?.entityId
      }`
    },
    approvalObjectRoute(state) {
      switch (state.approvalFlow?.type) {
        case 'PurchaseOrderFinish':
          return {
            name: 'purchase-order-details',
            params: { id: state.approvalFlow?.entityId },
          }
        case 'ServiceRequest':
          return {
            name: 'service-request-details',
            params: { id: state.approvalFlow.entityId },
          }
        default:
          return null
      }
    },
  },

  mutations: {
    ...storePagination.mutations,

    SET_APPROVAL_FLOWS(state, approvalFlows) {
      state.approvalFlows = approvalFlows
    },
    SET_APPROVAL_FLOW(state, approvalFlow) {
      state.approvalFlow = approvalFlow
    },
    SET_EVENTS(state, events) {
      state.events = events
    },
    SET_FILTER(state, filters) {
      state.filters = filters
    },
    SET_FILES(state, file) {
      state.files = file
    },

    CLEAN_APPROVAL_FLOW_STATE(state) {
      const { approvalFlow } = getInitialState()
      state.approvalFlow = approvalFlow
    },
    CLEAN_STATE(state) {
      const { filters, approvalFlow, approvalFlows } = getInitialState()
      state.filters = filters
      state.approvalFlow = approvalFlow
      state.approvalFlows = approvalFlows
    },
    CLEAN_FILE(state) {
      const { files } = getInitialState()
      state.files = files
    },
  },

  actions: {
    ...storePagination.actions,

    async fetchApprovalFlows({ commit, state }) {
      const { data } = await axios.get('/api/approval-flows/transactions', {
        params: {
          ...state.filters,
          pageSize: state.paging.pageSize,
          pageIndex: state.paging.currentPage - 1,
          sortBy: sorting.methods.getSorting(state),
        },
      })

      commit('SET_APPROVAL_FLOWS', data.results || [])
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: data.rowCount,
        rowsInCurrentPage: data.rowsInCurrentPage,
      })
    },

    async fetchEvents({ commit }, transactionId) {
      const { data } = await axios.get(`/api/approval-flows/transactions/${transactionId}/events`)
      commit('SET_EVENTS', data.results || [])
    },

    async fetchApprovalFlow({ commit }, id) {
      const { data } = await axios.get(`/api/approval-flows/transactions/${id}`)
      commit('SET_APPROVAL_FLOW', data)
    },

    async uploadDocuments({ state }, entityId) {
      const filesToUpload = state.files.filter(f => !f.id)
      if (filesToUpload.length > 0) {
        const formData = new FormData()
        formData.append('entityId', entityId)
        formData.append('uploadType', state.uploadType)
        filesToUpload.forEach(localFile => {
          formData.append('files', localFile.file.file)
          formData.append('types', localFile.type ?? 'Others')
        })
        await axios.post(`/api/documents/upload-files`, formData)
      }
    },

    deleteLocalFile({ commit, state }, index) {
      if (state.files[index]?.id) {
        commit('SET_FILES_DELETED', state.files[index].id)
      }
    },

    setFiles({ commit }, file) {
      commit('SET_FILES', file)
    },

    setApprovalFlow({ commit }, approvalFlow) {
      commit('SET_APPROVAL_FLOW', approvalFlow)
    },

    resetFilters({ commit }) {
      commit('SET_FILTER', getInitialState().filters)
    },

    cleanApprovalFlowState({ commit }) {
      commit('CLEAN_APPROVAL_FLOW_STATE')
    },
    cleanFileState({ commit }) {
      commit('CLEAN_FILE')
    },
    cleanState({ commit }) {
      commit('CLEAN_STATE')
      commit('CLEAN_FILE')
    },
  },
}
